import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  Button,
  Form,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Table } from "antd";
import "antd/lib/table/style/index.css";
import moment from "moment";
import { useForm } from "react-hook-form";
import Breadcrumb from "../common/breadcrumb";

import { neutral_list } from "../../services/neutrals";
import { displayName } from "../../utils/utils";
import { useHistory } from "react-router";

const Neutrals = () => {
  const history = useHistory();
  const { register, handleSubmit, reset, getValues } = useForm();
  const [BasicLineTab, setBasicLineTab] = useState("inactive");
  const [dataSource, setDataSource] = useState([]);
   const [loading, setLoading] = useState(false);
 const [activePage, setActivePage] = useState({
    current: 1,
    pageSize: 10,
  });
  const [inactivePage, setInactivePage] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    list_neutrals({
      page: inactivePage.current,
      per_page: inactivePage.pageSize,
      status: "inactive",
      order: "created_at desc",
    });
  }, []);

    const list_neutrals = async (params, activeTab) => {
      setLoading(true);
      const res = await neutral_list(params);
      if (res) {
        if (res.status) {
          setDataSource(res.data);
         if (activeTab === "active") {
           setActivePage({
             current: params.page,
             pageSize: params.per_page,
             total: res.total_entries,
           });
         } else {
           setInactivePage({
             current: params.page,
             pageSize: params.per_page,
             total: res.total_entries,
           });
          }
          setLoading(false);
        }
      }
    };


  
  const handleSearch = async (data) => {
    const res = await neutral_list({ query: data.search });
    if (res) {
      if (res.status) {
        setDataSource(res.data);
      }
    }
  };

  const clearFilter = () => {
    reset();
    if (BasicLineTab === "active") {
      list_neutrals({
        page: activePage.current,
        per_page: activePage.pageSize,
        status: BasicLineTab,
      });
    } else {
      list_neutrals({
        page: inactivePage.current,
        per_page: inactivePage.pageSize,
        status: BasicLineTab,
      });
    }
  };

  const columns = [
    {
      title: "Name",
      key: "first_name",
      fixed: "left",
      sortDirections: ["ascend", "descend"],
      sorter: () => {},
      render: ({ first_name, last_name }) => {
        return <strong>{displayName(first_name, last_name)}</strong>;
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      sortDirections: ["ascend", "descend"],
      sorter: () => {},
    },
    {
      title: "Phone",
      key: "phone",
      render: (record) => {
        if (record.phone) {
          return (
            <span>
              +{record.country_code} {record.phone}
            </span>
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        if (record.status === "active") {
          return <span className="badge badge-success">Active</span>;
        } else if (record.status === "inactive") {
          return <span className="badge badge-danger">In-Active</span>;
        }
      },
    },
    {
      title: "Created At",
      key: "created_at",
      defaultSortOrder: "descend",
      sortDirections: ["ascend", "descend"],
      sorter: () => {},
      render: (record) => {
        return moment(record.created_at).format("DD/MM/YYYY");
      },
    },
  ];

    function onPagination(page, filters, sorter, extra) {
      if (BasicLineTab === "active") {
        setActivePage({
          ...page,
        });
      } else {
        setInactivePage({
          ...page,
        });
      }
      let params = {
        page: page.current,
        per_page: page.pageSize,
        status: BasicLineTab,
      };
      if (sorter.order) {
        params.order = `${sorter.column.key} ${sorter.order === "descend" ? "desc" : ""
          }`;
      }
      list_neutrals(params, BasicLineTab);
    }
  

  return (
    <div>
      <Breadcrumb title="Neutrals" />

      <Container fluid>
        <Row className="row">
          <Col className="col-sm-12">
            <Card>
              <CardBody>
                <Row className=" mb-3">
                  <Col lg={5} md={6} sm={12}>
                    <Form
                      onSubmit={handleSubmit(handleSearch)}
                      id="neutral_search"
                      className="validation d-flex align-items-center justify-content-between"
                    >
                      {/* <Row form>
                        <Col xl={8} md={8} sm={8} xs={8}> */}
                      <div className="d-flex align-items-center flex-grow-1">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search"
                          name="search"
                          ref={register({required:true})}
                          // onChange={(e) => setSearch(e.target.value)}
                        />

                        {/* </Col>
                        <Col xl={4} md={4} sm={4} xs={4}> */}
                        <Button color="light" type="submit" className="h-100">
                          <i class="fa fa-search mr-2"> </i>Search
                        </Button>
                      </div>

                      {/* </Col>
                      </Row> */}
                    </Form>
                  </Col>
                  <Col
                    lg={7}
                    md={6}
                    sm={12}
                    className="d-flex justify-content-end"
                  >
                    {getValues("search") && getValues("search").length > 0 ? (
                      <Button color="primary" outline onClick={clearFilter}>
                        <i className="fa fa-times"></i> Clear Search
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                <Nav className="border-tab" tabs>
                  <NavItem>
                    <NavLink
                      className={BasicLineTab === "active" ? "active" : ""}
                      onClick={() => {
                        setBasicLineTab("active");
                        list_neutrals({
                          page: activePage.current,
                          per_page: activePage.pageSize,
                          status: "active",
                          order: "created_at desc",
                        },"active");
                      }}
                    >
                      Active
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={BasicLineTab === "inactive" ? "active" : ""}
                      onClick={() => {
                        setBasicLineTab("inactive");
                        list_neutrals({
                          page: inactivePage.current,
                          per_page: inactivePage.pageSize,
                          status: "inactive",
                          order: "created_at desc",
                        },"inactive");
                      }}
                    >
                      In-Active
                    </NavLink>
                  </NavItem>
                  <NavItem className="ml-auto">
                    <Link className="btn btn-success" to="/neutrals/create">
                      + Add Neutral
                    </Link>
                  </NavItem>
                </Nav>
                <Table
                  rowClassName="pointer"
                  loading={loading}
                  columns={columns}
                  dataSource={dataSource}
                  scroll={{ x: 600 }}
                  onChange={onPagination}
                  pagination={
                    BasicLineTab === "active" ? activePage : inactivePage
                  }
                  onRow={(r) => ({
                    onClick: () => history.push(`neutrals/${r.id}`),
                  })}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Neutrals;
