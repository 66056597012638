 export class ConfigDB {
   static data = {
     settings: {
       layout_type: "ltr",
       sidebar: {
         type: "default",
         body_type: "default",
       },
       sidebar_setting: "default-sidebar",
       sidebar_backround: "dark-sidebar",
     },
     color: {
       layout_version: "light",
       color: "color-3",
       primary_color: "#8e24aa",
       secondary_color: "#ff6e40",
       mix_layout: "default",
     },
     router_animation: "fadeIn",
   };
 }

export default ConfigDB;