import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./common/loader";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store/index";

import { authorizer } from "../utils/authorizer";

import { ScrollContext } from "react-router-scroll-4";
import PrivateRoutes from "../routes/PrivateRoutes";
import Layout from "../pages/Layout";

import LoginPage from "../pages/LoginPage";

const AppLayout = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (authorizer()) {
      setAuthenticated(true);
    }
  }, []);

  const login = () => {
    setAuthenticated(true);
  };

  const logout = () => {
    setAuthenticated(false);
    localStorage.removeItem("user");
  };

  return (
    <Provider store={store}>
      <BrowserRouter basename={"/"}>
        <ScrollContext>
          <div>
            <Loader />
            <div className="page-wrapper">
              <Layout auth={authenticated} logout={logout}>
                {!authorizer() ? (
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <LoginPage authenticate={login} />}
                    />
                    <Route render={() => <Redirect to="/" />} />
                  </Switch>
                ) : (
                  <PrivateRoutes logout={logout} />
                )}
              </Layout>
            </div>
            <ToastContainer />
          </div>
        </ScrollContext>
      </BrowserRouter>
    </Provider>
  );
};

export default AppLayout;
