import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col, Form, Button } from "reactstrap";
import "antd/lib/table/style/index.css";
import "antd/lib/pagination/style/index.css";
import { Table } from "antd";
import moment from "moment";
import { DatePicker } from "antd";
import "antd/lib/date-picker/style/index.css";
import Tag from "../common/Tag";
import { useForm } from "react-hook-form";

import { sort } from "../../utils/utils";
import Breadcrumb from "../common/breadcrumb";
import { allHearings } from "../../services/cases";
import HearingModal from "../common/HearingModal";

const Hearings = () => {
  const { RangePicker } = DatePicker;
  const { register, errors, handleSubmit } = useForm();
  const [origData, setOrigData] = useState();
  const [dataSource, setDataSource] = useState();
  const [selected, setSelected] = useState("");
  const [modal, setModal] = useState(false);
  const [hearing, setHearing] = useState();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    getAllHearings({
      page: pagination.current,
      per_page: pagination.pageSize,
    });
  }, []);

  const getAllHearings = async (params) => {
    setLoading(true);
    const res = await allHearings(params);
    if (res) {
      if (res.status) {
        setPagination({
          current: params.page,
          pageSize: params.per_page,
          total: res.total_entries,
        });
        setLoading(false);
        setOrigData(res.data);
        setDataSource(res.data);
      } else setLoading(false);
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  function onPagination(page, filters, sorter, extra) {
    setPagination({
      ...page,
    });
    let params = {
      page: page.current,
      per_page: page.pageSize,
    };
    if (sorter.order) {
      params.order = `${sorter.column.key} ${
        sorter.order === "descend" ? "desc" : ""
      }`;
    }
     if (filters.status) {
       params.status = `${filters.status}`;
     }
       
    getAllHearings(params);
  }

  const columns = [
    {
      title: "Agenda",
      key: "agenda",
      sortDirections: ["ascend", "descend"],
      sorter: () => {},
      render: ({ agenda }) => {
        return <strong>{agenda}</strong>;
      },
    },
    {
      title: "Subject",
      key: "subject",
      render: ({ case_file }) => {
        return case_file.subject;
      },
    },
    {
      title: "Party name",
      key: "party_name",
      render: ({ case_file }) => {
        return case_file.party_name;
      },
    },
    {
      title: "Date",
      key: "date",
      sortDirections: ["ascend", "descend"],
      sorter: () => {},
      render: ({ date }) => {
        return moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Start Time",
      key: "start_time",
      render: ({ date }) => {
        return moment(date).format("hh:mm:A");
      },
    },
    {
      title: "Status",
      key: "status",
      filters: [
        {
          text: "Scheduled",
          value: "scheduled",
        },
        {
          text: "Cancelled",
          value: "cancelled",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Expired",
          value: "expired",
        },
      ],
      filterMultiple: false,
      render: ({ status }) => {
        return <Tag status={status} />;
      },
    },
  ];

  return (
    <>
      <Breadcrumb title="Hearings" />
      <Container fluid className="hearings">
        <Card>
          <CardBody>
            <Table
              loading={loading}
              rowClassName="pointer"
              columns={columns}
              dataSource={dataSource}
              scroll={{ x: 800 }}
              onChange={onPagination}
              pagination={pagination}
              onRow={(r) => ({
                onClick: () => {
                  setHearing(r);
                  toggle();
                },
              })}
            />
          </CardBody>
        </Card>
        {modal && (
          <HearingModal
            open={modal}
            toggle={toggle}
            hearing={hearing}
            case_id={hearing.case_file.id}
            cb={() =>
              getAllHearings({
                page: pagination.current,
                per_page: pagination.pageSize,
              })
            }
          />
        )}
      </Container>
    </>
  );
};

export default Hearings;
