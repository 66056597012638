import {
  Home,
  Users,
  Briefcase,
  UserCheck,
  HelpCircle,
  Pocket,
  DollarSign,
} from "react-feather";

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    active: true,
  },
  {
    path: "/users",
    title: "Users",
    icon: Users,
    type: "link",
    active: false,
  },
  {
    path: "/neutrals",
    title: "Neutrals",
    icon: UserCheck,
    type: "link",
    active: false,
  },
  {
    path: "/cases",
    title: "Cases",
    icon: Briefcase,
    type: "link",
    active: false,
  },
  {
    path: "/hearings",
    title: "Hearings",
    icon: Pocket,
    type: "link",
    active: false,
  },
  {
    path: "/queries",
    title: "Queries",
    icon: HelpCircle,
    type: "link",
    active: false,
  },
];

