import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useParams, Link, useLocation } from "react-router-dom";
import moment from "moment";
import { Rss, User } from "react-feather";
import Breadcrumb from "../common/breadcrumb";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Scheduler from "./Sheduler";
import CreatePayment from "./CreatePayment";
import Tag from "../common/Tag";
import { displayName, getColor, subString } from "../../utils/utils";
import config from "../../utils/config.json";
import Avatar from "../../assets/images/avatar.jpg";

import StatusButton from "../common/StatusButton";
import NeutralDropdown from "../common/NeutralDropdown";
import HearingModal from "../common/HearingModal";
import { neutral_list } from "../../services/neutrals";
import DynamicAlert from "../common/DynamicAlert";
import {
  show_case,
  caseStatus,
  getAllComments,
  addComment,
  cancelPayment,
  updateComment,
  updateProcess,
  addRespondants,
} from "../../services/cases";

const CaseDetail = () => {
  const { case_id } = useParams();
  const { register, handleSubmit, errors, reset } = useForm();
 const {
   register: repRegister,
   errors: repErrors,
   handleSubmit: respHandleSubmit,
 } = useForm();
  const [scheduler, setScheduler] = useState(false);
  const [userCase, setUserCase] = useState();
  const [neutrals, setNeutrals] = useState([]);
  const [hearingModal, setHearingModal] = useState(false);
  const [hearing, setHearing] = useState();
  let query = new URLSearchParams(useLocation().search);
  const [payment, setPayment] = useState(false);
  const [comments, setComments] = useState([]);
  const [addResp, setAddResp] = useState(false);
  const [phone, setPhone] = useState();

  const commentRef = useRef();

  useEffect(() => {
    getCaseDetail();
    getComments();
    if (query.get("schedule")) {
      setScheduler(true);
    }
  }, []);

  const getCaseDetail = async () => {
    const userCase = await show_case(case_id);
    if (userCase) {
      if (userCase.status) {
        setUserCase(userCase.data);
      }
    }
    const neutralList = await neutral_list();
    if (neutralList) {
      if (neutralList.status) {
        setNeutrals(
          neutralList.data.filter(
            (n) =>
              n.status === "active" &&
              !userCase.data.neutrals.find((assign_n) => assign_n.id === n.id)
          )
        );
      }
    }
  };

  const getComments = async () => {
    const res = await getAllComments(case_id);
    if (res) {
      if (res.status) {
        setComments(res.data);
      }
    }
  };

  const handleStatusChange = async (status) => {
    const res = await caseStatus(case_id, status);

    if (res) {
      if (res.status) {

        toast.success("Case status updated successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        getCaseDetail();
      }
    }
  };

  const handleComment = async (data) => {
    const res = await addComment(case_id, data);
    if (res) {
      if (res.status) {
        toast.success("Comment Added", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
                reset();
        getCaseDetail();
         getComments();
      }
    }
  };

  const updateCommentStatus = async (comment_id, status) => {
    const res = await updateComment(case_id, comment_id, status);
    if (res) {
      if (res.status) {
        toast.success("Comment status updated", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getCaseDetail();
      }
    }
  };

  const handleCancelPayment = async (payment_id) => {
    // e.preventDefault()
    const res = await cancelPayment(case_id, payment_id);
    if (res) {
      if (res.status) {
        toast.success(`${res.message}`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getCaseDetail();
      }
    }
  };

  const handleProcess = async (e) => {
    const res = await updateProcess(case_id, e.target.value);
    if (res) {
      if (res.status) {
        toast.success(`${res.message}`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getCaseDetail();
      }
    }
  };

  const onRespSubmit = async (data) => {
    const res = await addRespondants(case_id, {
      respondants: [data],
    });
    if (res) {
      if (res.status) {
        toast.success(`${res.message}`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getCaseDetail();
        toggleAddResp();
      }
    }
  };

  //TOGGLES
  const toggleScheduler = () => {
    setScheduler(!scheduler);
  };

  const togglePayment = () => {
    setPayment(!payment);
  };

  const toggleHearing = () => {
    setHearingModal(!hearingModal);
  };

  const toggleAddResp = () => {
    setAddResp(!addResp);
  };

  return (
    <>
      <Breadcrumb parent="Cases" parent_url="/cases" title="Case Detail" />
      <DynamicAlert userType="neutral" case_id={case_id} />
      <Scheduler
        isOpen={scheduler}
        toggle={toggleScheduler}
        cb={getCaseDetail}
      />
      <CreatePayment
        isOpen={payment}
        toggle={togglePayment}
        cb={getCaseDetail}
        caseDetail={userCase}
      />
      {userCase && (
        <Container fluid>
          {hearingModal && (
            <HearingModal
              open={hearingModal}
              toggle={toggleHearing}
              hearing={hearing}
              case_id={case_id}
              cb={getCaseDetail}
            />
          )}
          <Row>
            <Col>
              <Card className="mb-3">
                <CardBody className="p-3 d-flex justify-content-end">
                  <>
                    <NeutralDropdown data={neutrals} cb={getCaseDetail} />
                    <>
                      <Button
                        onClick={togglePayment}
                        className="mr-3"
                        size="sm"
                        color="success"
                      >
                        Create Payment
                      </Button>
                      {userCase.status === "paid" && (
                        <Button
                          onClick={toggleScheduler}
                          className="mr-3"
                          size="sm"
                          color="success"
                        >
                          Schedule Hearing
                        </Button>
                      )}
                    </>
                    <StatusButton
                      onChange={(s) => handleStatusChange(s)}
                      statusState={userCase.status}
                    />
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="case_detail">
            <Col lg={8} className="d-flex align-items-stretch">
              <Card className="w-100">
                <CardBody>
                  <div className="header pb-3 mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center mb-3">
                        <h5 className="f-w-600 mb-0">{userCase.subject}</h5>
                        <Tag status={userCase.status} className="ml-3" />
                      </div>
                      <div className="d-flex align-items-center ">
                        {/* <label htmlFor="process">Process</label> */}
                        <select
                          name="process"
                          id="process"
                          className="form-control"
                          value={userCase.process}
                          onChange={handleProcess}
                        >
                          <option value="">Process</option>
                          <option value="arbitration">Arbitration</option>
                          <option value="conciliation">Conciliation</option>
                          <option value="mediation">Mediation</option>
                        </select>
                      </div>
                    </div>
                    <h6 className="text-black-50">
                      Case No: {userCase.case_no}
                    </h6>
                    <p className="text-black-50">
                      Created on {moment(userCase.created_at).format("ll")}
                    </p>
                  </div>
                  <div className="mb-4">
                    <h6>Process</h6>
                    <p className="f-14 f-w-300 text-capitalize">
                      {userCase.process ? userCase.process : "Not assigned"}
                    </p>
                  </div>
                  <div className="mb-4">
                    <h6>Background</h6>
                    <p className="f-14 f-w-300">{userCase.background}</p>
                  </div>
                  <div className="mb-4">
                    <h6>Claims</h6>
                    <p className="f-14 f-w-300">{userCase.claims}</p>
                  </div>
                  {userCase.existing_case_details.case_exist ? (
                    <div>
                      <h6>Existing case details</h6>
                      <p className="f-14 f-w-full">
                        {userCase.existing_case_details.case_exist_info}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <h6>Existing case details</h6>
                      <p className="f-14 f-w-full">
                        No existing case found for this record
                      </p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} className="d-flex align-items-stretch">
              <Row className="w-100 h-100">
                <Col lg={12} className="d-flex">
                  <Card className="w-100">
                    <CardBody className="height-equal log-content">
                      <h5 className="mb-3">Attachments</h5>
                      {userCase.attachments.length > 0 ? (
                        userCase.attachments.map((f) => (
                          <div className="logs-element">
                            <div className="circle-double-odd"></div>
                            <span>
                              <a
                                target="_blank"
                                href={`${config.BASE_URL}${f.path}`}
                              >
                                {f.title}
                              </a>
                            </span>
                            <span className="pull-right">
                              {moment(f.created_at).format("DD/MM/YYYY")}
                            </span>
                          </div>
                        ))
                      ) : (
                        <p className="text-black-50">No files found</p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12} className="d-flex">
                  <Card className="w-100">
                    <CardBody className="height-equal log-content">
                      <h5 className="mb-3">Existing case attachments</h5>
                      {userCase.existing_case_details.attachments.length > 0 ? (
                        userCase.existing_case_details.attachments.map((f) => (
                          <div className="logs-element">
                            <div className="circle-double-odd"></div>
                            <span>
                              <a
                                target="_blank"
                                href={`${config.BASE_URL}${f.path}`}
                              >
                                {f.title}
                              </a>
                            </span>
                            <span className="pull-right">
                              {moment(f.created_at).format("DD/MM/YYYY")}
                            </span>
                          </div>
                        ))
                      ) : (
                        <p className="text-black-50">
                          No existing case files found
                        </p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12}>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-3">Respondants</h5>
                        <p
                          style={{ cursor: "pointer" }}
                          className="main-theme-color"
                          onClick={toggleAddResp}
                        >
                          <i className="fa fa-plus"></i> Add
                        </p>
                      </div>
                      <div className="activity">
                        {userCase.respondants.length > 0 ? (
                          userCase.respondants.map((resp) => (
                            <div className="media">
                              <div className="gradient-round m-r-30">
                                <User />
                              </div>
                              <div className="media-body">
                                <h6 className="mb-2">{resp.first_name}</h6>
                                <p className="mb-1 text-black-50">
                                  <i className="fa fa-envelope mr-2"></i>
                                  {resp.email}
                                </p>
                                <p className="text-black-50">
                                  <i className="fa fa-mobile-phone mr-2"></i>
                                  {resp.phone}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-black-50">No respondants</p>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <h5 className="mb-3">Claimants</h5>
                      <div className="activity">
                        {userCase.claimants.length > 0 ? (
                          userCase.claimants.map((claim) => (
                            <div className="media">
                              <div className="gradient-round m-r-30">
                                <User />
                              </div>
                              <div className="media-body">
                                <h6 className="mb-2">{claim.first_name}</h6>
                                <p className="mb-1 text-black-50">
                                  <i className="fa fa-envelope mr-2"></i>
                                  {claim.email}
                                </p>
                                <p className="text-black-50">
                                  <i className="fa fa-mobile-phone mr-2"></i>
                                  {claim.phone}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-black-50">No respondants</p>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <h5 className="mb-3">Neutrals</h5>
                      <div className="new-users">
                        {userCase.neutrals.length > 0 ? (
                          <>
                            {userCase.neutrals.map((n) => (
                              <div className="media d-flex align-items-start mb-3">
                                {/* <img
                              className="rounded-circle img-40 image-radius m-r-15"
                              src={Avatar}
                              alt=""
                            /> */}
                                <div className="media-body mt-0">
                                  <h6 className="mb-0 f-w-700">
                                    {displayName(n.first_name, n.last_name)}
                                  </h6>
                                  <p className="mb-1">{n.email}</p>
                                  <p className="mb-1">{n.phone}</p>
                                </div>
                                <span className="pull-right align-self-end">
                                  <Link to={`/neutrals/${n.id}`}>
                                    {" "}
                                    View Details
                                  </Link>
                                </span>
                              </div>
                            ))}
                          </>
                        ) : (
                          <p>No neutrals assigned</p>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card>
                    <CardBody className="payments">
                      <h5 className="mb-3">Payments</h5>
                      {userCase.payments.length > 0 ? (
                        userCase.payments.map((p) => (
                          <div className={`payment-item ${p.status}`}>
                            <div className="content">
                              <Row>
                                <Col lg={7}>
                                  <p className="">{p.remarks}</p>
                                </Col>
                                <Col
                                  lg={5}
                                  className="d-flex align-items-center justify-content-end"
                                >
                                  <Tag
                                    className="mr-3"
                                    status={
                                      p.status === "created"
                                        ? p.status + "-payment"
                                        : p.status
                                    }
                                  />
                                  <h6>Total: ₹{p.amount.toFixed(2)}</h6>
                                </Col>
                              </Row>
                            </div>
                            {p.status === "created" && (
                              <div className="d-flex justify-content-end">
                                <a
                                  href
                                  className={`payment-button `}
                                  onClick={(e) => handleCancelPayment(p.id)}
                                >
                                  Cancel Payment
                                </a>
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <p>No payments initiated</p>
                      )}
                      {userCase.payments.length === 5 && (
                        <Link to={`/cases/${case_id}/payments`}>View More</Link>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12}>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody className="recent-notification">
                      <h5 className="mb-3">Hearings</h5>
                      {userCase.recent_hearings.length > 0 ? (
                        userCase.recent_hearings.map((h) => (
                          <>
                            <div
                              className="media hearing-item"
                              onClick={() => {
                                setHearing(h);
                                setHearingModal(true);
                              }}
                            >
                              <h6>{moment(h.date).format("DD-MM-YYYY")}</h6>
                              <div
                                className={`media-body ${getColor(h.status)}`}
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="f-w-600">{h.agenda}</span>
                                  <Tag status={h.status} />
                                </div>
                                <p className="m-0">
                                  Meeting Time:{" "}
                                  {moment(h.date).format("hh:mm A")}
                                </p>
                                <p className="mb-2">
                                  Meeting Link:{" "}
                                  <a href={h.start_url} target="_blank">
                                    {subString(h.start_url, 40)}
                                  </a>
                                </p>
                                <p className="f-12">
                                  {subString(h.description, 100)}
                                </p>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <p>No hearings yet</p>
                      )}

                      {userCase.recent_hearings.length === 5 && (
                        <Link
                          to={`/cases/${case_id}/hearings`}
                          className="float-right"
                        >
                          View More
                        </Link>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card>
                    <CardBody id="comment">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="mb-3">Comments</h5>
                        <p className="text-black-50 ">
                          {comments.length} Comments
                        </p>
                      </div>
                      <Form
                        onSubmit={handleSubmit(handleComment)}
                        className="validation"
                      >
                        <FormGroup>
                          <Label hidden>Title</Label>
                          <input
                            type="text"
                            name="title"
                            className="comment-text"
                            placeholder="Add a title"
                            ref={register({ required: true })}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label hidden>Content</Label>
                          <textarea
                            className="comment-text mb-2"
                            rows="3"
                            name="content"
                            placeholder="Add a comment"
                            ref={(e) => {
                              register(e, { required: true });
                              commentRef.current = e;
                            }}
                          ></textarea>
                          <span>{errors.content && "Comment is empty!"}</span>
                          <Button className="" type="submit">
                            Send
                          </Button>
                        </FormGroup>
                      </Form>
                      {userCase.case_comments.length > 0 && (
                        <div className="comments-container">
                          <ul className="list">
                            {userCase.case_comments.map((c) => (
                              <li className="list-item mb-4">
                                <div className="media-body mt-0 d-flex justify-content-between">
                                  <h6 className="mb-0 f-w-700">
                                    {c.title}
                                    <span className="time">
                                      {moment(c.created_at).from(moment())}
                                    </span>
                                  </h6>
                                  <div className="d-flex">
                                    <p className="mr-3">
                                      -
                                      {displayName(
                                        c.created_by.first_name,
                                        c.created_by.last_name
                                      )}
                                    </p>
                                    {c.status !== "blocked" && (
                                      <UncontrolledDropdown setActiveFromChild>
                                        <DropdownToggle
                                          tag="a"
                                          className="nav-link p-0"
                                        >
                                          <i className="fa fa-ellipsis-v main-theme-color pointer"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                          <DropdownItem
                                            active
                                            onClick={() =>
                                              updateCommentStatus(
                                                c.id,
                                                "blocked"
                                              )
                                            }
                                          >
                                            Block Comment
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    )}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="mb-1">{c.content}</p>
                                  <Tag status={c.status} />
                                </div>
                              </li>
                            ))}
                            {userCase.case_comments.length === 5 && (
                              <Link to={`/cases/${case_id}/comments`}>
                                View More
                              </Link>
                            )}
                          </ul>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Modal
            isOpen={addResp}
            toggle={toggleAddResp}
            centered
            title="Add Respondants"
          >
            <ModalHeader toggle={toggleAddResp}>Add Respondants</ModalHeader>
            <ModalBody>
              <Form id="respForm" className="validation">
                <FormGroup className="required">
                  <Label>Name</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="first_name"
                    ref={repRegister({ required: true })}
                  />
                  <span>{repErrors.first_name && "Name is required"}</span>
                </FormGroup>
                <FormGroup className="required">
                  <Label>Email</Label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    ref={repRegister({ required: true })}
                  />
                  <span>{repErrors.email && "Email is required"}</span>
                </FormGroup>
                <FormGroup className="required">
                  <Label>Mobile Number</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="phone"
                    value={phone}
                    maxLength="10"
                    pattern="^[0-9]*$"
                    onChange={(event) =>
                      setPhone(event.target.value.replace(/\D/, ""))
                    }
                    ref={repRegister({
                      required: true,
                      maxLength: "10",
                      minLength: "10",
                    })}
                  />
                  <span>{repErrors.phone && "Phone should be 10-digits"}</span>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                form="respForm"
                type="submit"
                onClick={respHandleSubmit(onRespSubmit)}
              >
                Add Respondant
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      )}
    </>
  );
};

export default CaseDetail;
