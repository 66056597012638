import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  CardBody,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { Table } from "antd";
import "antd/lib/table/style/index.css";
import moment from "moment";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { sort, displayName } from "../../utils/utils";
import { query_list, update_status } from "../../services/queries";

const Queries = () => {
  const [modal, setModal] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [query, setQuery] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    person: {},
  });
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("pending");
  const { register, handleSubmit, setValue } = useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    fetchQueries({
      page: pagination.current,
      per_page: pagination.pageSize,
      status: filter,
      order: "created_at desc",
    });
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const fetchQueries = async (params) => {
    setLoading(true);
    const res = await query_list(params);
    if (res) {
      if (res.status) {
        setDataSource(res.data);
        setPagination({
          current: params.page,
          pageSize: params.per_page,
          total: res.total_entries,
        });
        setLoading(false);
      } else setLoading(false);
    }
  };

  const changeStatus = async (data) => {
    const res = await update_status(query.id, data.status, "user_complaint");
    if (res) {
      if (res.status) {
        toast.success("Query status updated successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        fetchQueries({
          page: pagination.current,
          per_page: pagination.pageSize,
          status: filter,
        });
        toggle();
      }
    }
  };

  const tag = (status) => {
    switch (status) {
      case "pending":
        return <span className="badge badge-warning font-light">Pending</span>;
      case "inprogress":
        return <span className="badge badge-info font-light">In-progress</span>;
      case "closed":
        return <span className="badge badge-danger font-light">Closed</span>;
      default:
        return;
    }
  };

  function onPagination(page, filters, sorter, extra) {
    setPagination({
      ...page,
    });
    let params = {
      page: page.current,
      per_page: page.pageSize,
    };
    if (filter) {
      params.status = filter;
    }
    if (sorter.order) {
      params.order = `${sorter.column.key} ${
        sorter.order === "descend" ? "desc" : ""
      }`;
    }
    fetchQueries(params);
  }

  const filterQuery = (e) => {
    const value = e.target.name;
    if (value === filter) {
      setFilter();
      fetchQueries({
        page: 1,
        per_page: 10,
      });
    } else {
      setFilter(e.target.name);
      fetchQueries({
        page: 1,
        per_page: 10,
        status: e.target.name,
      });
    }
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      fixed: "left",
      sortDirections: ["ascend", "descend"],
      sorter: () => {},
      render: (record) => {
        if (record.person) {
          return (
            <strong>
              {displayName(record.person.first_name, record.person.last_name)}
            </strong>
          );
        } else return <strong>{record.name}</strong>;
      },
    },
    {
      title: "E-mail",
      key: "email",
      sortDirections: ["ascend", "descend"],
      sorter: () => {},
      render: (record) => {
        if (record.person) {
          return record.person.email;
        } else return record.email;
      },
    },
    {
      title: "Phone",
      key: "phone",
      render: (record) => {
        if (record.person) {
          return <span>+91 {record.person.phone}</span>;
        } else return <span>+91 {record.phone}</span>;
      },
    },
    {
      title: "Type",
      key: "person_type",
      render: (record) => {
        if (record.person) {
          return record.person.user_type;
        } else return "Guest";
      },
    },

    {
      title: "Status",
      key: "status",
      sorter: sort("string", "status"),
      render: ({ status }) => {
        return tag(status);
      },
    },
    {
      title: "Created At",
      key: "created_at",
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "descend",
      sorter: () => {},
      render: (record) => {
        return moment(record.created_at).format("DD/MM/YYYY");
      },
    },
  ];
  return (
    <>
      {/* <Card>
          <CardBody> */}
      <Row>
        <Col className="d-flex justify-content-end mb-2">
          <div class="filter-container">
            <ul class="ks-cboxtags">
              <li>
                <input
                  type="checkbox"
                  id="checkboxOne"
                  name="pending"
                  defaultChecked
                  onChange={filterQuery}
                  checked={filter === "pending" && true}
                />
                <label for="checkboxOne" className="warning">
                  Pending
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="checkboxTwo"
                  name="inprogress"
                  ref={register}
                  onChange={filterQuery}
                  checked={filter === "inprogress" && true}
                />
                <label for="checkboxTwo" className="primary">
                  In-Progress
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="checkboxFifteen"
                  name="closed"
                  ref={register}
                  onChange={filterQuery}
                  checked={filter === "closed" && true}
                />
                <label for="checkboxFifteen" className="danger">
                  closed
                </label>
              </li>
            </ul>
          </div>
        </Col>
      </Row>

      <Table
        rowClassName="pointer"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onPagination}
        pagination={pagination}
        scroll={{ x: 600 }}
        onRow={(r) => ({
          onClick: () => {
            toggle();
            setQuery(r);
          },
        })}
      />
      <Modal isOpen={modal} toggle={toggle} centered>
        {/* {setValue("status", query.status)} */}
        <ModalHeader toggle={toggle}>
          {query.person
            ? displayName(query.person.first_name, query.person.last_name)
            : query.name}
        </ModalHeader>
        <ModalBody>
          <p style={{ whiteSpace: "pre-wrap" }}>{query.message}</p>
          <div className="pt-3">
            <p className="mb-1 text-black-50">
              <i className="fa fa-envelope mr-2"></i>
              <a
                href={`mailto:${
                  query.person ? query.person.email : query.email
                }`}
              >
                {query.person ? query.person.email : query.email}
              </a>
            </p>
            <p className="text-black-50 mb-1">
              <i className="fa fa-phone mr-2"></i>
              {query.person ? query.person.phone : query.phone}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Form inline onSubmit={handleSubmit(changeStatus)}>
            <FormGroup>
              <select
                name="status"
                className="form-control d-inline"
                defaultValue={query.status}
                style={{ width: "fit-content" }}
                ref={register()}
              >
                <option value="pending">Pending</option>
                <option value="inprogress">In-progress</option>
                <option value="closed">Closed</option>
              </select>
            </FormGroup>
            <Button color="success" type="submit">
              Update
            </Button>
          </Form>
        </ModalFooter>
      </Modal>
      {/* </CardBody>
        </Card> */}
    </>
  );
};

export default Queries;
