import request from "../utils/request"
import config from "../utils/config.json";

export async function login(data) {
  return request(`${config.BASE_URL}/admin/v1/login`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}
