import decode from "jwt-decode"

export const authorizer = () => {
      try {
        let token = localStorage.getItem("user");
        if (!token) {
          return false;
        }
        const { exp } = decode(token);
        if (exp < new Date().getTime() / 1000) {
         return false
        }
      } catch (err) {
        return false;
      }
      return true;
}