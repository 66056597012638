import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**

import App from "./components/app";

// Import custom Components 


//firebase Auth
function Root() {
    useEffect(() => {
        const layout = localStorage.getItem("layout_version") || "light";
        const color = localStorage.getItem("color") || "color-2";
        document.body.classList.add(layout);
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);
    }, []);
    return (
        <div className="App">
            <App />            
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();