import React, { useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import userImg from "../../assets/images/user.png";
import { toast } from "react-toastify";
import { useParams, Link } from "react-router-dom";
import { Edit } from "react-feather";
import { useForm } from "react-hook-form";
import { Card, CardBody, Container, Row, Col } from "reactstrap";

import { update_user_status } from "../../services/users";
import { show_user } from "../../services/users";
import moment from "moment";

import { displayName } from "../../utils/utils";
import Tag from "../common/Tag";

const UserDetail = () => {
  const { register, handleSubmit, setValue } = useForm({
    shouldUnregister: false,
  });
  const { id } = useParams();
  const [user, setUser] = useState();
  const fields = ["email", "phone"];

  useEffect(() => {
    showUser()
  }, []);

  const showUser = async () => {
    const res = await show_user(id)
     if (res) {
       if (res.status) {
         const user = res.data;
         setValue("status", user.status === "active" ? true : false);
         setUser(user);
         fields.map((f) => setValue(f, user[f]));
       }
     }
  }

  const handleStatus = async (data) => {
    const res = await update_user_status({
      status: data.status ? "active" : "inactive",
      id,
    });
    if (res) {
      if (res.status) {
        toast.success("User status updated successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        showUser()
        setValue("status", data.status);
      }
    }
  };

  return (
    <>
      <Breadcrumb parent="Users" parent_url="/users" title="User Detail" />
      {user && (
        <Container fluid id="profile">
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} className="left-container py-3 d-flex flex-column ">
                  <div className="d-flex align-items-center flex-column">
                    <img
                      src={userImg}
                      alt="user"
                      className="img-200 rounded-circle mb-3"
                    />
                    <span>
                      Status:{" "}
                      <input
                        id="s2d"
                        type="checkbox"
                        className="switch form-control"
                        name="status"
                        onChange={handleSubmit(handleStatus)}
                        ref={register}
                      />
                    </span>
                  </div>
                </Col>
                <Col lg={9} className="pl-5 right-container">
                  <div className="mt-3 mb-4">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <h3 className="name m-0">
                          {user.salutation && user.salutation}
                          {displayName(user.first_name, user.last_name)}
                        </h3>
                        <Tag className="ml-3" status={user.status} />
                      </div>
                      <Link className="f-18 mr-3" to={`/users/${id}/edit`}>
                        <i className="fa fa-edit"></i> Edit
                      </Link>
                    </div>
                    <p className="text-black-50">
                      Created on {moment(user.created_at).format("DD/MM/YYYY")}
                    </p>
                    {user.last_login && (
                      <p className="text-black-50">
                        Last Login{" "}
                        {moment(user.last_login).format("DD/MM/YYYY")}
                      </p>
                    )}
                  </div>
                  <Row>
                    <Col lg={4} md={6} className="mt-sm-3 mt-xs-3">
                      <div className="detail-item">
                        <p
                          className={`heading dot ${
                            user.email_verified && "verified"
                          }`}
                        >
                          Email
                        </p>
                        <p className="content">{user.email}</p>
                      </div>
                    </Col>
                    <Col lg={6} md={6} className="mt-sm-3 mt-xs-3">
                      <div className="detail-item">
                        <p
                          className={`heading dot ${
                            user.phone_verified && "verified"
                          }`}
                        >
                          Phone
                        </p>
                        <p className="content">{`+${user.country_code} ${user.phone}`}</p>
                      </div>
                    </Col>
                    {user?.signup_activity?.case_type && (
                      <Col lg={4} md={6} className="mt-sm-3 mt-xs-3">
                        <div className="detail-item">
                          <p className={`heading`}>Nature of Disputes</p>
                          <p className="content">
                            {user?.signup_activity?.case_type}
                          </p>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  );
};

export default UserDetail;
