import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { DatePicker } from "antd";
import "antd/lib/date-picker/style/index.css";
import moment from "moment";
import Tag from "../common/Tag";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import { subString } from "../../utils/utils";
import { rescheduleHearing, cancelHearing } from "../../services/cases";

const HearingModal = ({ open, toggle, hearing, case_id , cb }) => {
  const { register, handleSubmit, reset, errors, control } = useForm();
  const [selected, setSelected] = useState("action");
  const history = useHistory();

  // useEffect(() => {

  // }, [selected])

  const onReschedule = async (data) => {
    const res = await rescheduleHearing(case_id, {
      case_hearing_id: hearing.id,
      date: moment(data.date).format(),
    });
    if (res) {
      if (res.status) {
        toast.success("Hearing rescheduled successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }

    toggle();
    cb();
  };

  const onCancel = async () => {
    const res = await cancelHearing(case_id, hearing.id);
    if (res) {
      if (res.status) {
        toast.success("Hearing Cancelled", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
    toggle();
    cb();
  };

  return (
    <>
      {hearing && (
        <Modal isOpen={open} toggle={toggle} centered>
          {/* {setValue("status", query.status)} */}
          <ModalHeader toggle={toggle}>
            <div className="d-flex align-items-center">
              {hearing.agenda}
              <Tag className="ml-3 f-12" status={hearing.status} />
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <h6>
                Date: {moment(hearing.date).format("DD/MM/YYYY")}{" "}
                {moment(hearing.date).format("hh:mm: A")}
              </h6>
              <p>{hearing.description}</p>
              <div className="pt-3">
                <p className="mb-1 text-black-50">
                  Meeting Link:{" "}
                  <a target="_blank" href={hearing.start_url}>
                    {subString(hearing.start_url, 35)}
                  </a>
                </p>
                {hearing.mom && (
                  <p>
                    <strong>Minutes of Meeting:</strong> {hearing.mom}
                  </p>
                )}
              </div>
            </div>
            {hearing.case_file && (
              <div>
                <span>
                  <strong>Case:</strong>{" "}
                  <Link to={`/cases/${hearing.case_file.id}`}>
                    {hearing.case_file.subject}{" "}
                  </Link>
                </span>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <>
              {selected === "reschedule" && (
                <Form
                  id="reschedule"
                  className="w-100 validation"
                  onSubmit={handleSubmit(onReschedule)}
                >
                  <FormGroup>
                    <Label>Reschedule to</Label>
                    <Controller
                      name="date"
                      control={control}
                      rules={{ required: true }}
                      as={
                        <DatePicker
                          placeholder="Select Date & Time"
                          className="p-2 w-100"
                          format="YYYY-MM-DD HH:mm"
                          use12Hours
                          minuteStep={15}
                          showTime={{
                            defaultValue: moment("00:00:00", "HH:mm"),
                          }}
                        />
                      }
                    ></Controller>
                    <span>{errors.date && "Select date & time"}</span>
                  </FormGroup>
                </Form>
              )}
              {(hearing.status === "scheduled" ||
                hearing.status === "rescheduled" ||
                hearing.status === "expired") && (
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="text-capitalize"
                    color={selected === "cancel" ? "danger" : "success"}
                  >
                    {selected}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => setSelected("reschedule")}>
                      Reschedule Hearing
                    </DropdownItem>
                    {hearing.status !== "expired" && (
                      <DropdownItem onClick={() => setSelected("cancel")}>
                        Cancel Hearing
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {selected === "reschedule" ? (
                <Button outline color="success" form="reschedule" type="submit">
                  Update
                </Button>
              ) : selected === "cancel" ? (
                <Button outline color="success" onClick={onCancel}>
                  Update
                </Button>
              ) : null}
            </>
            {selected !== "action" && (
              <p className="f-12 mt-3">
                {selected === "reschedule"
                  ? "Click update to reschedule the hearing"
                  : "Click update to cancel the hearing"}
              </p>
            )}
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default HearingModal;
