import request from "../utils/request";
import config from "../utils/config.json";

export async function user_list(params) {
  const token = localStorage.getItem("user");

  return request(
    `${config.BASE_URL}/admin/v1/users`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      params
    }
  );
}

export async function create_user(data) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function edit_user(id, data) {
  const token = localStorage.getItem("user");
  return request(`${config.BASE_URL}/admin/v1/users/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: {
      users: data,
    },
  });
}

export async function update_user_status({ id, status }) {
  const token = localStorage.getItem("user");
  return request(`${config.BASE_URL}/admin/v1/users/${id}/status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: {
      status,
    },
  });
}

export async function show_user(id) {
  const token = localStorage.getItem("user");
  return request(`${config.BASE_URL}/admin/v1/users/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}