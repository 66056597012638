import React, { useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import userImg from "../../assets/images/user.png";
import { update_neutral_status } from "../../services/neutrals";
import { show_neutral } from "../../services/neutrals";
import { toast } from "react-toastify";
import { useParams, Link } from "react-router-dom";
import { Edit } from "react-feather";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
} from "reactstrap";

import { displayName } from "../../utils/utils";
import Tag from "../common/Tag";

const UserDetail = () => {
  const { id } = useParams();
  const { register, handleSubmit, setValue } = useForm({
    shouldUnregister: false,
  });
  const [neutral, setNeutral] = useState();
  const fields = ["email", "phone"];

  useEffect(() => {
    show_neutral(id).then((res) => {
      if (res) {
        if (res.status) {
          const neutral = res.data;
          setValue("status", neutral.status === "active" ? true : false);
          setNeutral(neutral);
          fields.map((f) => setValue(f, neutral[f]));
        }
      }
    });
  }, []);

  const handleStatus = async (data) => {
    const res = await update_neutral_status({
      status: data.status ? "active" : "inactive",
      id,
    });
    if (res) {
      if (res.status) {
        toast.success("Neutral status updated successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setNeutral(res.data);
        setValue("status", data.status);
      }
    }
  };

  return (
    <>
      <Breadcrumb
        parent="Neutrals"
        parent_url="/neutrals"
        title="Neutral Detail"
      />
      {neutral && (
        <Container fluid id="profile">
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} className="left-container py-3 d-flex flex-column ">
                  <div className="d-flex align-items-center flex-column">
                    <img
                      src={userImg}
                      alt="user"
                      className="img-200 rounded-circle mb-3"
                    />
                    <span>
                      Status:{" "}
                      <input
                        id="s2d"
                        type="checkbox"
                        className="switch form-control"
                        name="status"
                        onChange={handleSubmit(handleStatus)}
                        ref={register}
                      />
                    </span>
                  </div>
                  <Card className="mt-3 flex-grow-1 mb-0">
                    <CardBody>
                      <h6 className="mb-3">Documents</h6>
                      {neutral.file_uploads.length > 0 ? (
                        <>
                          {neutral.file_uploads.map((file) => (
                            <div className="d-flex justify-content-between">
                              <p>{file.title}</p>
                              <span>
                                {moment(file.created_at).format("DD/MM/YYYY")}
                              </span>
                            </div>
                          ))}
                        </>
                      ) : (
                        <p className="text-black-50">No files uploaded</p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={9} className="pl-5 right-container">
                  <div className="mt-3 mb-4">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <h3 className="name m-0">
                          {neutral.salutation && neutral.salutation}
                          {displayName(neutral.first_name, neutral.last_name)}
                        </h3>
                        <Tag className="ml-3" status={neutral.status} />
                      </div>
                      <Link className="f-18 mr-3" to={`/neutrals/${id}/edit`}>
                        <i className="fa fa-edit"></i> Edit
                      </Link>
                    </div>

                    <p className="text-black-50">
                      Created on{" "}
                      {moment(neutral.created_at).format("DD/MM/YYYY")}
                    </p>
                    {neutral.last_login && (
                      <p className="text-black-50">
                        Last Login{" "}
                        {moment(neutral.last_login).format("DD/MM/YYYY")}
                      </p>
                    )}
                  </div>
                  <Row>
                    <Col lg={4} md={6} className="mt-sm-3 mt-xs-3">
                      <div className="detail-item">
                        <p
                          className={`heading dot ${
                            neutral.email_verified && "verified"
                          }`}
                        >
                          Email
                        </p>
                        <p className="content">{neutral.email}</p>
                      </div>
                    </Col>
                    <Col lg={4} md={6} className="mt-sm-3 mt-xs-3">
                      <div className="detail-item">
                        <p
                          className={`heading dot ${
                            neutral.phone_verified && "verified"
                          }`}
                        >
                          Phone
                        </p>
                        <p className="content">{`+${neutral.country_code} ${neutral.phone}`}</p>
                      </div>
                    </Col>
                  </Row>
                  {neutral.accreditations.length > 0 && (
                    <Row>
                      <Col>
                        <h5 className="mt-4">Accreditations</h5>
                        {neutral.accreditations.map((acc) => (
                          <Row>
                            <Col lg={4} md={6}>
                              <div className="detail-item">
                                <p className="heading">Name</p>
                                <p className="content">{acc.name}</p>
                              </div>
                            </Col>
                            <Col lg={4} md={6}>
                              <div className="detail-item">
                                <p className="heading">Institution</p>
                                <p className="content">{acc.institution}</p>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  );
};

export default UserDetail;
