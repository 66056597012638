import React, { useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Row, Col, Card, CardBody, Container } from "reactstrap";

import Calendar from "../common/Calendar";
import { dashboardTiles } from "../../services/dashboard";

const Dashboard = () => {
  const [info, setInfo] = useState({
    users: { active: 0, inactive: 0 },
    neutrals: { active: 0, inactive: 0 },
    case_files: { all: 0, closed: 0 },
  });

  const dashInfo = async () => {
    const res = await dashboardTiles();
    if (res) {
      if (res.status) {
        setInfo(res.data);
      }
    }
  };

  useEffect(() => {
    dashInfo();
  }, []);

    return (
      <>
        <Breadcrumb title="Dashboard" />
        <Container fluid className="ad">
          <Row>
            <Col xl={7} lg={12} md={12}>
                <Row>
                  <Col lg={4}>
                    <div className="ad-card">
                      <div className="header">
                        <h3>Users</h3>
                      </div>
                      <ul className="card-list">
                        <li className="card-list-item green">
                          <div>Active</div>
                          <h5>{info.users.active}</h5>
                        </li>
                        <li className="card-list-item red">
                          <div>In active</div>
                          <h5>{info.users.inactive}</h5>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="ad-card">
                      <div className="header">
                        <h3>Neutrals</h3>
                      </div>
                      <ul className="card-list">
                        <li className="card-list-item green">
                          <div>Active</div>
                          <h5>{info.neutrals.active}</h5>
                        </li>
                        <li className="card-list-item red">
                          <div>In active</div>
                          <h5>{info.neutrals.inactive}</h5>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="ad-card">
                      <div className="header">
                        <h3>Cases</h3>
                      </div>
                      <ul className="card-list">
                        <li className="card-list-item green">
                          <div>All</div>
                          <h5>{info.case_files.all}</h5>
                        </li>
                        <li className="card-list-item red">
                          <div>Closed</div>
                          <h5>{info.case_files.closed}</h5>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
            </Col>
            <Col xl={5} lg={12} md={12}>
              <Card>
                <CardBody>
                  <Calendar />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
}

export default Dashboard
