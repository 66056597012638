import request from "../utils/request";
import config from "../utils/config.json";

export async function neutral_list(params) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/neutrals`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    params,
  });
}

export async function show_neutral(id) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/neutrals/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}

export async function create_neutral(data) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/neutrals`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function edit_neutral(id, data) {
  const token = localStorage.getItem("user");
  return request(`${config.BASE_URL}/admin/v1/neutrals/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: {
      neutrals: data,
    },
  });
}

export async function update_neutral_status({ id, status }) {
  const token = localStorage.getItem("user");
  return request(`${config.BASE_URL}/admin/v1/neutrals/${id}/status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: {
      status,
    },
  });
}

