import React, { useState, useEffect } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
  ButtonDropdown,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

const StatusButton = ({ onChange, statusState }) => {
  const [dropDown, setDropDown] = useState(false);
  const [status, setStatus] = useState(statusState);
  const [count, setCount] = useState(0);
  const status_arr = ["in_review", "accepted", "on_hold", "rejected", "closed"];

  useEffect(() => {
    if (count > 0) {
      onChange(status);
    }
    setCount(1);
  }, [status]);

  useEffect(() => {
    setStatus(statusState);
  }, [statusState]);

  const toggle = () => {
    setDropDown(!dropDown);
  };

  const statusName = (status) => {
    return status.replace("_", " ");
  };

  const switchStatus = () => {
    const nextStatus = status_arr.indexOf(status) + 1;
    if (nextStatus < status_arr.length) {
      setStatus(status_arr[nextStatus]);
    } else {
      onChange(status_arr[0]);
      return setStatus(status_arr[0]);
    }
  };

  const getNextStatus = () => {
    const nextStatus = status_arr.indexOf(status) + 1;
    if (nextStatus < status_arr.length) {
      return status_arr[nextStatus];
    } else return status_arr[0];
  };

  return (
    <div id="admin_cd">
      <div className="admin_cd_status">
        <ButtonGroup className={status}>
          <ButtonDropdown isOpen={dropDown} toggle={toggle}>
            <DropdownToggle className="admin_status_drop ">
              {statusName(status)}
            </DropdownToggle>
            <DropdownMenu className="p-2" right>
              <DropdownItem
                className="in_review"
                onClick={() => setStatus("in_review")}
              >
                <div></div>IN REVIEW
              </DropdownItem>
              <DropdownItem
                className="accepted"
                onClick={() => setStatus("accepted")}
              >
                <div></div>ACCEPTED
              </DropdownItem>
              <DropdownItem
                className="on_hold"
                onClick={() => setStatus("on_hold")}
              >
                <div></div>ON HOLD
              </DropdownItem>
              <DropdownItem
                className="rejected"
                onClick={() => setStatus("rejected")}
              >
                <div></div>REJECTED
              </DropdownItem>
              <DropdownItem
                className="closed"
                onClick={() => setStatus("closed")}
              >
                <div></div>CLOSED
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          {/* <Button
            className="p-2 admin_status_arrow"
            onClick={switchStatus}
            id="status_tip"
          >
            <i className="fa fa-caret-right"></i>
          </Button> */}
          {/* <UncontrolledTooltip placement="top" target="status_tip">
            Next status{" "}
            <span className="text-uppercase">
              [{statusName(getNextStatus())}]
            </span>
          </UncontrolledTooltip> */}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default StatusButton;
