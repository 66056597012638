import React, { useState } from "react";
import { X } from "react-feather";
import { Form, Label, FormGroup, Button, Input } from "reactstrap";
import { useParams } from "react-router-dom";
import { DatePicker, Radio } from "antd";
import "antd/lib/date-picker/style/index.css";
import { toast } from "react-toastify";

import { sheduleHearing } from "../../services/cases";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";

const Scheduler = ({ isOpen, toggle, cb }) => {
  const { case_id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    errors,
    control,
    setValue,
  } = useForm();
  const [link, setLink] = useState("auto");

  const formSubmit = async (data) => {
    const payload = {
      agenda: data.agenda,
      date: moment(data.date).format(),
      meeting_link: data.meeting_link,
      description: data.description,
      link_type: link,
    };

    const res = await sheduleHearing(case_id, payload);
    if (res) {
      if (res.status) {
        toast.success("Hearing scheduled successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        reset();
        setValue("date", "");
        toggle();
        cb();
      }
    }
  };

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const disabledDate = (current) => {
    return current < moment().subtract(1, "day");
  };

  return (
    <div>
      <div
        className={`right-sidebar sidebar-full ${isOpen && "show"}`}
        id="right_side_bar"
      >
        <div className="container p-0">
          <div className="modal-header p-l-20 p-r-20">
            <div className="col-sm-8 p-0">
              <h6 className="modal-title font-weight-bold">Schedule Hearing</h6>
            </div>
            <div className="col-sm-4 text-right p-0">
              <X onClick={() => toggle()} className="pointer" />
            </div>
          </div>
        </div>
        <div
          className="p-3"
          style={{ height: "calc(100vh - 140px)", overflowY: "scroll" }}
        >
          <Form
            className="validation"
            id="hearing_schedule_form"
            onSubmit={handleSubmit(formSubmit)}
          >
            <FormGroup>
              <Label>Agenda</Label>
              <input
                className="form-control"
                type="text"
                name="agenda"
                ref={register({ required: true })}
              />
              <span>{errors.agenda && "Add an agenda"}</span>
            </FormGroup>
            <FormGroup>
              <Label className="d-block">Date & Time</Label>
              <Controller
                name="date"
                control={control}
                rules={{ required: true }}
                as={
                  <DatePicker
                    disabledDate={disabledDate}
                    placeholder=""
                    className="p-2 w-100"
                    format="YYYY-MM-DD HH:mm"
                    use12Hours
                    minuteStep={15}
                    showTime={{ defaultValue: moment("00:00:00", "HH:mm") }}
                  />
                }
              ></Controller>
              <span>{errors.date && "Select date & time"}</span>
            </FormGroup>
            <FormGroup>
              <Label>Meeting Link Generation</Label>
              <Radio.Group
                onChange={(e) => setLink(e.target.value)}
                value={link}
              >
                <Radio value="auto">Auto</Radio>
                <Radio value="manual">Manual</Radio>
              </Radio.Group>
            </FormGroup>
            {link === "manual" && (
              <FormGroup>
                <Label>Meeting link</Label>
                <input
                  className="form-control"
                  type="text"
                  name="meeting_link"
                  ref={register({ required: true })}
                />
                <span>{errors.meeting_link && "Add an meeting link"}</span>
              </FormGroup>
            )}
            <FormGroup>
              <Label>Add Description</Label>
              <textarea
                className="form-control"
                rows="5"
                name="description"
                ref={register()}
              />
            </FormGroup>
          </Form>

          <Button
            block
            color="success"
            type="submit"
            form="hearing_schedule_form"
          >
            Schedule
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Scheduler;
