import React from "react";
import { withRouter } from "react-router";


import logo from "../assets/images/logo.svg";

import Login from "../components/Login/Login"

// import request from "../utils/request"

const LoginPage = ({ history,authenticate }) => {


    
    return (
      <div>
        <div className="page-wrapper">
          <div className="container-fluid p-0 login-container">
            <div className="authentication-main admin-bg">
              <div className="row">
                <div className="col-sm-12 p-0">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <div className="text-center">
                        <img style={{ width: "30%" }} src={logo} alt="" />
                      </div>
                      <Login authenticate={authenticate} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default withRouter(LoginPage);