import React from "react";

import Header from "../components/common/header-component/header";
import Sidebar from "../components/common/sidebar-component/sidebar";
import Footer from "../components/common/footer";
import ThemeCustomizer from "../components/common/theme-customizer";


const Layout = ({ children, auth , logout }) => {


    return (
      <>
        {!auth ? (
          <>{children}</>
        ) : (
          <>
            <div className="page-body-wrapper">
              <Header logout={logout} />
              <Sidebar />
              <div className="page-body">{children}</div>
              <Footer />
              {/* <ThemeCustomizer /> */}
            </div>
          </>
        )}
      </>
    );
}

export default Layout
