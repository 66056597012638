import React, { useState, useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import moment from "moment";
import { getColor, subString } from "../../utils/utils";
import { allCaseHearings } from "../../services/cases";
import HearingModal from "../common/HearingModal";
import Tag from "../common/Tag";

const AllHearings = ({ user, neutral }) => {
  const { case_id } = useParams();
  const [hearings, setHearings] = useState([]);
  const [hearingModal, setHearingModal] = useState(false);
  const [hearing, setHearing] = useState();

  useEffect(() => {
    getHearings();
  }, []);

  const getHearings = async () => {
   const res = await allCaseHearings(case_id);
    if (res) {
      if (res.status) {
       setHearings(res.data);
      }
    }
  };

  const toggleHearing = () => {
    setHearingModal(!hearingModal);
  };

  return (
    <>
      <Breadcrumb
        grand_parent="Cases"
        grand_parent_url="/cases"
        parent="Case Detail"
        parent_url={`/cases/${case_id}`}
        title="Hearings"
      />
      <Container fluid>
        {hearingModal && (
          <HearingModal
            open={hearingModal}
            toggle={toggleHearing}
            hearing={hearing}
            case_id={case_id}
            cb={getHearings}
          />
        )}
        <Card>
          <CardBody className="recent-notification">
            <h5 className="mb-3">Hearings</h5>
            <div>
              {hearings.length > 0 &&
                hearings.map((h) => (
                  <>
                    <div
                      className="media hearing-item"
                      onClick={() => {
                        setHearing(h);
                        setHearingModal(true);
                      }}
                    >
                      <h6>{moment(h.date).format("DD-MM-YYYY")}</h6>
                      <div className={`media-body ${getColor(h.status)}`}>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="f-w-600">{h.agenda}</span>
                          <Tag status={h.status} />
                        </div>
                        <p className="m-0">
                          Meeting Time: {moment(h.date).format("hh:mm A")}
                        </p>
                        <p className="mb-2">
                          Meeting Link:{" "}
                          <a href={h.meeting_link}>
                            {subString(h.meeting_link, 40)}
                          </a>
                        </p>
                        <p className="f-12">{h.description}</p>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AllHearings;
