import request from "../utils/request";
import config from "../utils/config.json";

export async function query_list(params) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/contact_queries`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    params,
  });
}

export async function update_status(id, status, category) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/contact_queries/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: {
      status,
      category,
    },
  });
}
