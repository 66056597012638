/**
 :: Request network request tool.
    More detailed api documentation: https://github.com/umijs/umi-request.
    Adapted from ant-design-pro
 */
import { extend } from "umi-request";
import { toast } from "react-toastify";


const codeMessage = {
  200: "The server successfully returned the requested data.",
  201: "The new or modified data was successful.",
  202: "A request has entered the background queue (asynchronous task).",
  204: "The deletion of data was successful.",
  400: "There was an error in the request and the server did not take action to create or modify the data.",
  401: "The user does not have permissions (token, user name, password error).",
  403: "Users are authorized, but access is prohibited.",
  404: "Requests are made for records that do not exist and the server does not operate.",
  406: "The format of the request is not available.",
  410: "The requested resource is permanently deleted and is no longer available.",
  422: "When an object is created, a validation error occurs.",
  500: "There is an error with the server, please check the server.",
  502: "Gateway error.",
  503: "The service is not available, the server is temporarily overloaded or maintained.",
  504: "The gateway timed out.",
};

// /**
// :: Exception handler.
// */
const errorHandler = async (error) => {
  const { response } = error;

  if (response && response.status) {
    const data = await response.json();
    if (data.data) {
      toast.error(`${data.data[0]}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (data.message) {
      toast.error(`${data.message}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error(`${data.error}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return { status: false };
    }
    return data;
  } else if (!response) {
    toast.error("Cannot connect with the server!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
  return response;
};

/**
 Configure the default parameters for request requests.
 */
const request = extend({
  errorHandler, // Default error handling.
  // credentials: 'include'
});

export default request;

