import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";

import { authorizer } from "../utils/authorizer";

import Dashboard from "../components/Dashboard/Dashboard"
import Users from "../components/Users/Users";
import Neutrals from "../components/Neutrals/Neutrals";
import UserDetail from "../components/Users/UserDetail";
import NeutralDetail from "../components/Neutrals/NeutralDetail";
import AllCases from "../components/Cases/AllCases";
import CaseDetail from "../components/Cases/CaseDetail";
import CreateUser from "../components/Users/CreateUser";
import EditUser from "../components/Users/EditUser";
import CreateNeutral from "../components/Neutrals/CreateNeutral";
import EditNeutral from "../components/Neutrals/EditNeutral";
import Queries from "../components/Queries/Queries";
import CaseHearings from "../components/Cases/CaseHearings";
import AllHearings from "../components/Cases/AllHearings";
import AllPayments from "../components/Cases/AllPayments";
import AllComments from "../components/Cases/AllComments";



const PrivateRoutes = ({ logout }) => {
  

const PrivateRoute = ({ component: Component, role, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authorizer() ? (
          <Component {...rest} />
        ) : (
          (logout(), (<Redirect to="/" />))
        )
      }
    />
  );
};

    return (
      <Switch>
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/cases" component={AllCases} />
        <PrivateRoute exact path="/cases/:case_id" component={CaseDetail} />
        <PrivateRoute
          exact
          path="/cases/:case_id/hearings"
          component={CaseHearings}
        />
        <PrivateRoute
          exact
          path="/cases/:case_id/payments"
          component={AllPayments}
        />
        <PrivateRoute
          exact
          path="/cases/:case_id/comments"
          component={AllComments}
        />
        <PrivateRoute exact path="/hearings" component={AllHearings} />
        <PrivateRoute exact path="/users/create" component={CreateUser} />
        <PrivateRoute exact path="/users/:id" component={UserDetail} />
        <PrivateRoute exact path="/users/:id/edit" component={EditUser} />
        <PrivateRoute exact path="/neutrals/create" component={CreateNeutral} />
        <PrivateRoute exact path="/neutrals" component={Neutrals} />
        <PrivateRoute exact path="/neutrals/:id" component={NeutralDetail} />
        <PrivateRoute exact path="/neutrals/:id/edit" component={EditNeutral} />
        <PrivateRoute exact path="/queries" component={Queries} />
      </Switch>
    );
}

export default PrivateRoutes
