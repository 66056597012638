import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { Table } from "antd";
import { toast } from "react-toastify";

import { cancelPayment } from "../../services/cases";
import { allPayment } from "../../services/cases";
import { useParams, Link } from "react-router";
import Tag from "../common/Tag";

const AllPayments = () => {
  const { case_id } = useParams();
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    getAllPayments();
  }, []);

  const getAllPayments = async () => {
    const res = await allPayment(case_id);
    if (res) {
      if (res.status) {
        setPayments(res.data);
      }
    }
  };

  const handleCancelPayment = async (payment_id) => {
    // e.preventDefault()
    const res = await cancelPayment(case_id, payment_id);
    if (res) {
      if (res.status) {
        toast.success(`${res.message}`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getAllPayments();
      }
    }
  };

  return (
    <>
      <Breadcrumb
        title="Payments"
        parent="Case Detail"
        parent_url={`/cases/${case_id}`}
        grand_parent="cases"
        grand_parent_url={"/cases/"}
      />
      <Container fluid>
        <Card>
          <CardBody className="payments">
            {/* <h5 className="mb-3">Payments</h5> */}
            {payments.length > 0 ? (
              payments.map((p) => (
                <div className={`payment-item ${p.status}`}>
                  <div className="content">
                    <Row>
                      <Col lg={7}>
                        <p className="">{p.remarks}</p>
                      </Col>
                      <Col
                        lg={5}
                        className="d-flex align-items-center justify-content-end"
                      >
                        <Tag
                          className="mr-3"
                          status={
                            p.status === "created"
                              ? p.status + "-payment"
                              : p.status
                          }
                        />
                        <h6>Total: ₹{p.amount.toFixed(2)}</h6>
                      </Col>
                    </Row>
                  </div>
                  {p.status === "created" && (
                    <div className="d-flex justify-content-end">
                      <a
                        href
                        className={`payment-button `}
                        onClick={(e) => handleCancelPayment(p.id)}
                      >
                        Cancel Payment
                      </a>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No payments initiated</p>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AllPayments;
