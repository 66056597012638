import React, { useState, useEffect } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { Users } from "react-feather";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Avatar from "../../assets/images/avatar.jpg";
import { displayName } from "../../utils/utils";
import { assignNeutral } from "../../services/cases";

const NeutralDropdown = ({ data, cb }) => {
  const { case_id } = useParams();
  const [dropDown, setDropDown] = useState(false);
  const [neutrals, setNeutrals] = useState();

  useEffect(() => {
    setNeutrals(data);
  }, [data]);

  const toggle = () => {
    setDropDown(!dropDown);
  };

  const handleAssignment = async (neutral_id) => {
    const neutral_arr = [neutral_id];
    const res = await assignNeutral(case_id, neutral_arr);
    if (res) {
      if (res.status) {
        toast.success("Neutral assigned successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        cb();
      }
    }
  };

  const handleSearch = (e) => {
    setNeutrals(
      data.filter(
        (n) =>
          displayName(n.first_name, n.last_name)
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          n.email.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <div id="assign_neutral">
      <Dropdown isOpen={dropDown} toggle={toggle} className="h-100">
        <DropdownToggle className="btn mr-3" caret>
          Assign Neutral
        </DropdownToggle>
        <DropdownMenu className="menu" right>
          <div className="search-container">
            <Users />
            <input
              className="input"
              placeholder="Search for Neutral"
              onChange={handleSearch}
            />
          </div>
          <div className="item-container">
            {neutrals ? (
              <>
                {neutrals.length > 0 ? (
                  <>
                    {neutrals.map((n) => (
                      <DropdownItem
                        className="menu-item"
                        onClick={() => handleAssignment(n.id)}
                      >
                        {/* <div className="profile-pic-container mr-2">
                      <img
                        src={Avatar}
                        alt="Neutral"
                        loading="lazy"
                        className="profile-pic "
                      />
                    </div> */}
                        <div className="content">
                          <h6 className="mb-0">
                            {displayName(n.first_name, n.last_name)}
                          </h6>
                          <p>{n.email}</p>
                        </div>
                      </DropdownItem>
                    ))}
                  </>
                ) : (
                  <p>No active neutrals</p>
                )}
              </>
            ) : (
              "Neutrals not found"
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default NeutralDropdown;
