import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { Form, Label, FormGroup, Button, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Select } from "antd";

import { useForm, Controller } from "react-hook-form";
import moment from "moment";

import { createPayment } from "../../services/cases";

const CreatePayment = ({ isOpen, toggle, cb, caseDetail }) => {
  const { case_id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    errors,
    watch,
    setValue,
    getValues,
    control,
  } = useForm();
  const [persons, setPersons] = useState({
    claimants: [],
    respondants:   []
  });;;

  const commission = watch("commission", 0);
  const gst = watch("gst", 0);
  const neutral_fee = watch("neutral_fee", 0);

  useEffect(() => {
    const total = parseInt(commission) + parseInt(gst) + parseInt(neutral_fee);
    setValue("amount", total);
  }, [commission, gst, neutral_fee]);

  useEffect(() => {
    if (caseDetail) {
      setPersons({
        claimants: caseDetail.claimants,
        respondants: caseDetail.respondants
      })
    }
  }, [caseDetail])

  const formSubmit = async (data) => {
    const payload = {
      amount: data.amount,
      remarks: data.remarks,
      split_up: {
        commission: data.commission,
        gst: data.gst,
        neutral_fee: data.neutral_fee,
      },
      claimant_ids: data.claimant_ids  ?  data.claimant_ids : [],
      respondant_ids:  data.respondant_ids ? data.respondant_ids  :  [],
    };

    const res = await createPayment(case_id, payload);
    if (res) {
      if (res.status) {
        toast.success("Payment created successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        reset({
          claimant_ids: [],
          respondant_ids: [],
        });
        toggle();
        cb();
      }
    }
  };

  return (
    <div>
      <div
        className={`right-sidebar sidebar-full ${isOpen && "show"}`}
        id="right_side_bar"
      >
        <div className="container p-0">
          <div className="modal-header p-l-20 p-r-20">
            <div className="col-sm-8 p-0">
              <h6 className="modal-title font-weight-bold">Create Payment</h6>
            </div>
            <div className="col-sm-4 text-right p-0">
              <X onClick={() => toggle()} className="pointer" />
            </div>
          </div>
        </div>
        <div
          className="p-3"
          style={{ height: "calc(100vh - 140px)", overflowY: "scroll" }}
        >
          <Form
            className="validation"
            id="creaet_payment_form"
            onSubmit={handleSubmit(formSubmit)}
          >
            <div className="form-group row required">
              <label className="col-sm-6 col-form-label">
                Administrative Cost
              </label>
              <div className="col-sm-6">
                <input
                  type="number"
                  className="form-control"
                  name="commission"
                  ref={register({ required: true })}
                />
              </div>
              <span>{errors.commission && "Commission is required"}</span>
            </div>
            <div className="form-group row required">
              <label className="col-sm-6 col-form-label">Neutral fees</label>
              <div className="col-sm-6">
                <input
                  type="number"
                  className="form-control"
                  name="neutral_fee"
                  ref={register({ required: true })}
                />
              </div>
              <span>{errors.neutral_fee && "Neutral fee is required"}</span>
            </div>
            <div className="form-group row required">
              <label className="col-sm-6 col-form-label">GST</label>
              <div className="col-sm-6">
                <input
                  type="number"
                  className="form-control"
                  name="gst"
                  ref={register({ required: true })}
                />
              </div>
              <span>{errors.gst && "GST is required"}</span>
            </div>

            <FormGroup>
              <Label>Amount</Label>
              <input
                className="form-control"
                type="number"
                name="amount"
                ref={register({ required: true })}
              />
              <span>{errors.amount && "Amount is required"}</span>
            </FormGroup>
            <div className="form-group required">
              <label className="col-form-label">Assign Claimants</label>
              <div className="">
                <Controller
                  name="claimant_ids"
                  control={control}
                  // rules={{ required: true }}
                  as={
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      // ref={register()}
                    >
                      {persons.claimants?.map((claimant) => (
                        <Select.Option value={claimant.id}>
                          {claimant.first_name}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                ></Controller>
              </div>
              <span>{errors.claimant_ids && "Select one Claimant"}</span>
            </div>
            {persons.respondants?.length > 0 && (
              <div className="form-group  required">
                <label className=" col-form-label">Assign Respondants</label>
                <div className="">
                  <Controller
                    name="respondant_ids"
                    control={control}
                    // rules={{ required: true }}
                    as={
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                      >
                        {persons.respondants?.map((resp) => (
                          <Select.Option value={resp.id}>
                            {resp.first_name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                  ></Controller>
                </div>
                <span>{errors.respondant_ids && "Select one Respondant"}</span>
              </div>
            )}
            <FormGroup>
              <Label>Remarks</Label>
              <textarea
                className="form-control"
                rows="5"
                name="remarks"
                ref={register()}
              />
            </FormGroup>
          </Form>

          <Button
            block
            color="success"
            type="submit"
            form="creaet_payment_form"
          >
            Create Payment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreatePayment;
