import request from "../utils/request";
import config from "../utils/config.json";

export async function dashboardTiles() {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/tiles`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}
