import React, { Fragment } from 'react';
import avatar from "../../../assets/images/avatar.jpg";
import { LogOut } from "react-feather";
import { Button } from "reactstrap";
import {useHistory} from "react-router-dom"


const UserMenu = ({ logout }) => {
  const history = useHistory()

  const handleLogOut = () => {
    localStorage.removeItem('user')
    logout();
    history.replace('/')
    }


    return (
      <Fragment>
        <Button onClick={handleLogOut} color="danger">
          Log out
        </Button>
        {/* <li className="onhover-dropdown">
          <div className="media align-items-center">
            <img
              className="align-self-center pull-right img-40 rounded-circle blur-up lazyloaded"
              src={avatar}
              alt="header-user"
            />

            <div className="dotted-animation">
              <span className="animate-circle"></span>
              <span className="main-circle"></span>
            </div>
          </div>
          <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li>
              <a href="#javascript">
                <User />
                Edit Profile
              </a>
            </li>
            <li>
              <a href="#javascript">
                <Mail />
                Inbox
              </a>
            </li>
            <li>
              <a href="#javascript">
                <Lock />
                Lock Screen
              </a>
            </li>
            <li>
              <a href="#javascript">
                <Settings />
                Settings
              </a>
            </li>
            <li>
              <a onClick={handleLogOut}>
                <LogOut /> Log out
              </a>
            </li>
          </ul>
        </li> */}
      </Fragment>
    );
};


export default UserMenu;