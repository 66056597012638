import React from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import Breadcrumb from "../common/breadcrumb";
import { create_neutral } from "../../services/neutrals";

const CreateNeutral = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, setValue } = useForm();
  setValue("last_name", "");

  const createNeutral = async (data) => {
    const res = await create_neutral({ neutrals: data });
    if (res) {
      if (res.status) {
        toast.success("Neutral created updated successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        history.push(`/neutrals/${res.data.id}`);
      }
    }
  };

  return (
    <>
      <Breadcrumb
        parent="Neutrals"
        parent_url="/neutrals"
        title="Create Neutral"
      />

      <Container fluid>
        <Card>
          <CardBody>
            <>
              <Row>
                <Col lg={8} md={12}>
                  <Form
                    id="signupForm"
                    className="theme-form validation"
                    onSubmit={handleSubmit(createNeutral)}
                    noValidate
                  >
                    <Row form>
                      <Col lg={2}>
                        <FormGroup className="required">
                          <Label>Salutation</Label>
                          <select
                            name="salutation"
                            className="form-control digits"
                            ref={register({ required: true })}
                          >
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                          </select>
                          <span>
                            {errors.salutation && "Salutation is required"}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col lg={5} md={12}>
                        <FormGroup>
                          <Label>First Name</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="first_name"
                            ref={register({ required: true })}
                          />
                          <span>
                            {errors.first_name && "First name is required"}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col lg={5} md={12}>
                        <FormGroup>
                          <Label>Last Name</Label>
                          <input
                            className="form-control"
                            type="text"
                            name="last_name"
                            ref={register}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Label>Mobile Number</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        onChange={(event) =>
                          setValue(
                            "phone",
                            event.target.value.replace(/\D/, "")
                          )
                        }
                        ref={register({ required: true })}
                      />
                      <span>{errors.phone && "Mobile Number is required"}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label>E-mail</Label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        ref={register({
                          required: "Enter your e-mail",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Enter a valid e-mail address",
                          },
                        })}
                      />
                      <span>{errors.email && errors.email.message}</span>
                    </FormGroup>
                    <FormGroup>
                      <Label>Status</Label>
                      <select
                        className="form-control"
                        name="status"
                        ref={register}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">In-Active</option>
                      </select>
                    </FormGroup>
                    <FormGroup>
                      {/* <Label>Send Welcome</Label> */}
                      <div className="checkbox checkbox-primary pl-3">
                        <>
                          <input
                            type="checkbox"
                            name="send_welcome"
                            id="checkbox"
                            defaultChecked
                            ref={register}
                          />
                          <label className="w-100" htmlFor="checkbox">
                            Send Welcome
                          </label>
                        </>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Button type="submit" color="primary">
                        Create
                      </Button>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default CreateNeutral;
