import React from "react";
import { useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

import { login } from "../../services/login"
import { useForm } from "react-hook-form";

    

const Login = ({authenticate}) => {
  const history = useHistory()
  const { register, handleSubmit, errors } = useForm();

  const handleLogin = async (data) => {
    const res = await login(data);
    if (res) {
      if (res.status) {
        localStorage.setItem("user", res.data.token);
         localStorage.setItem("user_name", res.data.first_name.toLowerCase());
        authenticate();
        history.replace("/dashboard");
      }
    }
  };

    return (
      <Card className="mt-4 p-4">
        <CardBody>
          <div className="text-center">
            <h4>Admin Login</h4>
          </div>
          <Form
            noValidate
            className=" theme-form validation"
            onSubmit={handleSubmit(handleLogin)}
          >
            <FormGroup>
              <Label>E-mail</Label>
              <input
                className="form-control"
                type="email"
                name="user_name"
                ref={register({
                  required: "E-mail is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Enter a valid e-mail address",
                  },
                })}
              />
              <span>{errors.user_name && errors.user_name.message}</span>
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <input
                className="form-control"
                type="password"
                name="password"
                ref={register({ required: true })}
              />
              <span>{errors.password && "Password is required"}</span>
            </FormGroup>
            <FormGroup>
              <Button block type="submit" color="primary">
                Login
              </Button>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    );
}

export default Login; 
