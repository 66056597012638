import request from "../utils/request";
import config from "../utils/config.json";

export async function case_list(params) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    params,
  });
}

export async function show_case(id) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}

export async function case_status(id, data) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${id}/status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function allCaseHearings(id) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${id}/hearings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}

export async function allHearings(params) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/hearings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    params,
  });
}

export async function sheduleHearing(case_id, data) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${case_id}/hearing`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function rescheduleHearing(case_id, data) {
  const token = localStorage.getItem("user");

  return request(
    `${config.BASE_URL}/admin/v1/case_files/${case_id}/reschedule_hearing`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data,
    }
  );
}

export async function cancelHearing(case_id, case_hearing_id) {
  const token = localStorage.getItem("user");

  return request(
    `${config.BASE_URL}/admin/v1/case_files/${case_id}/cancel_hearing`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: { case_hearing_id },
    }
  );
}

export async function assignNeutral(case_id, neutral_arr) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${case_id}/neutrals`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: {
      neutral_ids: neutral_arr,
    },
  });
}

export async function caseStatus(case_id, status) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${case_id}/status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: {
      status,
    },
  });
}

export async function addComment(case_id, data) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${case_id}/comment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function getAllComments(case_id, page, per_page) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${case_id}/comments`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    params: {
      page,
      per_page,
    },
  });
}

export async function updateComment(case_id, comment_id, status) {
  const token = localStorage.getItem("user");

  return request(
    `${config.BASE_URL}/admin/v1/case_files/${case_id}/comment_status`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: {
        comment_id,
        status,
      },
    }
  );
}


export async function createPayment(case_id, data) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${case_id}/payments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  });
}

export async function cancelPayment(case_id, payment_id) {
  const token = localStorage.getItem("user");

  return request(
    `${config.BASE_URL}/admin/v1/case_files/${case_id}/cancel_payment`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: {
        payment_id,
      },
    }
  );
}

export async function allPayment(case_id) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${case_id}/payments`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
}


export async function updateProcess(case_id, process) {
  const token = localStorage.getItem("user");

  return request(`${config.BASE_URL}/admin/v1/case_files/${case_id}/process`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: {
      process,
    },
  });
}

export async function getCalendarEvents(month, year) {
  const token = localStorage.getItem("user");

  return request(
    `${config.BASE_URL}/admin/v1/case_files/calender${
      month && year ? `/?month=${month}&year=${year}` : ""
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );
}


export async function addRespondants(case_id, data) {
  const token = localStorage.getItem("user");

  return request(
    `${config.BASE_URL}/admin/v1/case_files/${case_id}/create_respondants`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data,
    }
  );
}


