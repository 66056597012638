import React, { useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  CardBody,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import UserQueries from './UserQueries'
import SecureContract from "./SecureContract"

const Queries = () => {

      const [activeTab, setActiveTab] = useState("1");

      const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
      };

    return (
      <div>
        <Breadcrumb title="Queries" />
        <Container fluid>
          <Card>
            <CardBody>
              <Nav tabs className="border-tab">
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Queries
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Secure your contracts
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <UserQueries />
                </TabPane>
                <TabPane tabId="2">
                  <SecureContract />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
}

export default Queries
